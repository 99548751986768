import React, { useContext, useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Cart.css';
import CartContext from './CartContext';
import butterimg from '../../assets/Sized.png';

const Cart: React.FC<{ isVisible: boolean, onVisibilityChange: (isVisible: boolean) => void }> = ({ isVisible, onVisibilityChange }) => {
  const { cart, removeFromCart, addToCart, clearCart } = useContext(CartContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const phoneNumberRef = useRef(phoneNumber);
  const fullNameRef = useRef("");
  const emailRef = useRef("");
  const shippingAddressRef = useRef("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    phoneNumberRef.current = phoneNumber;
  }, [phoneNumber]);

  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  const handleRemoveFromCart = (productName: string, price: number) => {
    removeFromCart({ productName, price, quantity: 1 });
  };

  const handleAddToCart = (productName: string, price: number) => {
    addToCart({ productName, price, quantity: 1 });
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handleSubmitOrder = async () => {
    const itemsAsString = cart.map((item) => `${item.productName} (x${item.quantity}): $${item.price.toFixed(2)}`).join("\n");

    const data = {
      service_id: process.env.REACT_APP_SERVICE_ID,
      template_id: process.env.REACT_APP_TEMPLATE_ID,
      user_id: process.env.REACT_APP_USER_ID,
      template_params: {
        to_name: fullNameRef.current,
        reply_to: emailRef.current,
        shipping_address: shippingAddressRef.current,
        phone_number: phoneNumberRef.current,
        items: itemsAsString,
        total_price: totalPrice.toFixed(2),
      },
    };

    try {
      const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        setShowSuccessMessage(true);
        clearCart();
        setTimeout(() => {
          setShowSuccessMessage(false);
          onVisibilityChange(false);
        }, 10000);
      } else {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      alert('Oops... ' + JSON.stringify(error));
    }
  };

  return (
    <div className={isVisible ? "cart-container" : "cart-container hidden"}>
      <h2>Your Cart</h2>
      {cart.map((item, index) => (
        <div key={index} className="cart-item">
          <h3>{item.quantity}x </h3>
          <img src={butterimg} alt="Butter" className="butterimg" />
          <h4>{item.productName}</h4>
          <h4>${item.price.toFixed(2)}</h4>
          <button onClick={() => handleAddToCart(item.productName, item.price)}>+</button>
          <button onClick={() => handleRemoveFromCart(item.productName, item.price)}>-</button>
        </div>
      ))}
      <h3>Sub Total: ${totalPrice.toFixed(2)}</h3>
      <h4>Make sure to input the correct information below and we will reach out to you with total cost including the shipping. After that, we will ship your order. </h4>
      
      <label htmlFor="fullname" className='namelabel'>Full Name</label>
      <input id="fullname" className='namefield' placeholder="Full Name" onChange={(e) => fullNameRef.current = e.target.value} required />

      <label htmlFor="phoneNumber" className='phonelabel'>Phone Number</label>
      <input id="phoneNumber" className='phonefield' placeholder="Phone Number" value={phoneNumber} onChange={handlePhoneNumberChange} required />

      <label htmlFor="email" className='emaillabel'>Email</label>
      <input id="email" className='emailfield' placeholder="Email" onChange={(e) => emailRef.current = e.target.value} required />

      <label htmlFor="shippingAddress" className='addresslabel'>Shipping Address</label>
      <input id="shippingAddress" className='addressfield' placeholder="Shipping Address" onChange={(e) => shippingAddressRef.current = e.target.value} required />

      <button className="SubmitOrder" onClick={handleSubmitOrder}>Submit Order</button>

      {showSuccessMessage && <div className="success-message">Order submitted successfully. Thank you!</div>}
    </div>
  );
};

export default Cart;
